export const ontarioBoundary = {
    "type": "FeatureCollection",
    "features": [{
        "type": "Feature",
        "properties": {},
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -88.9453125,
                        56.992882804633986
                    ],
                    [
                        -95.537109375,
                        53.014783245859235
                    ],
                    [
                        -95.712890625,
                        48.86471476180277
                    ],
                    [
                        -90.263671875,
                        47.57652571374621
                    ],
                    [
                        -88.505859375,
                        47.69497434186282
                    ],
                    [
                        -84.638671875,
                        46.13417004624326
                    ],
                    [
                        -82.79296874999999,
                        44.715513732021336
                    ],
                    [
                        -83.583984375,
                        42.16340342422401
                    ],
                    [
                        -82.79296874999999,
                        41.244772343082076
                    ],
                    [
                        -78.75,
                        42.68243539838623
                    ],
                    [
                        -78.486328125,
                        43.26120612479979
                    ],
                    [
                        -76.37695312499999,
                        43.26120612479979
                    ],
                    [
                        -73.564453125,
                        45.213003555993964
                    ],
                    [
                        -74.619140625,
                        46.07323062540835
                    ],
                    [
                        -75.76171875,
                        45.89000815866184
                    ],
                    [
                        -78.31054687499999,
                        46.6795944656402
                    ],
                    [
                        -78.837890625,
                        47.45780853075031
                    ],
                    [
                        -78.92578124999999,
                        51.6180165487737
                    ],
                    [
                        -80.5078125,
                        55.62799595426723
                    ],
                    [
                        -88.9453125,
                        56.992882804633986
                    ]
                ]
            ]
        }
    }]
}