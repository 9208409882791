// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/SPCI_Home_Hero.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu-button{margin-right:16px}.header{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover}.header-appbar{background-color:rgba(64,80,84,.8) !important;height:95px}.header-toolbar{justify-content:space-between}.menu-button:hover{background-color:inherit !important}", "",{"version":3,"sources":["webpack://./src/app/core/navbar/NavBar.scss"],"names":[],"mappings":"AAAA,aACI,iBAAA,CAGJ,QACI,wDAAA,CACA,qBAAA,CAGJ,eACI,6CAAA,CACA,WAAA,CAGJ,gBACI,6BAAA,CAGJ,mBACI,mCAAA","sourcesContent":[".menu-button {\r\n    margin-right: 16px;\r\n}\r\n\r\n.header {\r\n    background-image: url('../../../assets/images/SPCI_Home_Hero.jpg');\r\n    background-size: cover;\r\n}\r\n\r\n.header-appbar {\r\n    background-color: rgba(64, 80, 84, .8) !important;\r\n    height: 95px;\r\n}\r\n\r\n.header-toolbar {\r\n    justify-content: space-between;\r\n}\r\n\r\n.menu-button:hover {\r\n    background-color: inherit !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
