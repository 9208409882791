import React, { useState, ChangeEvent, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import L from 'leaflet';

import NavBar from '../core/navbar/NavBar';
//import FileUploader from './FileUploader/FileUploader'
import './Upload.scss';
import Authentication from '../../services/Authentication';
import LandZoneAPI from '../../services/Landzones';

export default function Upload() {
	 
    const history = useHistory();
    if (Authentication.status() === false) {
        history.push('/');
    }
	const [name, setName] = useState<string>();
	const [fileSelected, setFileSelected] = React.useState<File>(); // also tried <string | Blob>
	const [uploadStatus, setUploadStatus] = useState<string>("test");

	const handleFileChange = function (e: React.ChangeEvent<HTMLInputElement>) {
		const fileList = e.target.files;
		if (!fileList) return;
		setFileSelected(fileList[0]);
		setName(fileList[0].name);
	  };
	

	function parseObject(obj)
{
   for(var key in obj)
   {
      console.log("key: " + key + ", value: " + obj[key])
      if(obj[key] instanceof Object)
      {
        parseObject(obj[key]);
      }
   }
}
   const uploadFile = async function (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
	   const reader = new FileReader();
	   reader.onload = async function(e){
			let json = JSON.stringify(e.target.result) ;
			let parsedJSON = JSON.parse(json);
			let actualJSON = JSON.parse(parsedJSON);
			console.log(actualJSON);
			//converting parsed geoJSON to layer and then back to geoJSON gives us free data validation for the API
			//the API is very brittle, it does not handle many variations of geoJSON
			//psyyych this did NOT help
			/*
			let layeredJson =  L.geoJSON(actualJSON);
			const geojson = layeredJson.toGeoJSON();
         */
			
			
			if (actualJSON.type == "FeatureCollection"){
				let newZones = [];
				actualJSON.features.forEach( function(value){
					newZones.push({
						geoJSON: value
					})
				});
				
				await LandZoneAPI.submit(newZones);
				history.push('/',{
				uploadStatus: true,
				uploadMessage: "File was successfully uploaded!",
				});
				
			}else if (actualJSON.type == "Feature"){			
				var newZone = {
						geoJSON: actualJSON
				}   
				console.log(newZone);
				await LandZoneAPI.submit([newZone]);
				history.push('/',{
				uploadStatus: true,
				uploadMessage: "File was successfully uploaded!",
			});;
			}else{
				history.push('/',{
					uploadStatus: false,
					uploadMessage: "File could not be uploaded!  Your geoJSON file must conatin only a Feature or FeatureCollection",
				});
			}
			
			/*
			var newZone = {
						geoJSON: actualJSON
				}   
				console.log(newZone);
				await LandZoneAPI.submit([newZone]);
				history.push('/');
				*/
	   }
	   
	   reader.readAsBinaryString(fileSelected);
  };
  
  

    return (
        <div className='sharedpath-upload'>
            <NavBar/>
            <FormControl className='upload-form'>
                <FormLabel className='upload-label'>Upload a Map File</FormLabel>
                <Grid container spacing={3}>
					<Grid item xs={12}>
						<p>This app only accepts .json and .geoJSON files in valid geoJSON format.</p>
						<p>Visit <a href="https://mapshaper.org/">mapshaper</a> to convert other formats such as TopoJSON, csv and Shapefiles</p>
					</Grid>
                    <Grid item xs={12} sm={6}>
								<label className="custom-file-upload">
								Choose a File:
								 <input
									  accept=".json, .JSON, .geoJSON"
									  id="landzoneFile"
									  name="landzoneFile"
									  type="file"
									  multiple={false}
									  onChange={handleFileChange}
									/>
								</label>

                    </Grid>
					<Grid item xs={12} sm={6}>
					<div className="file-upload-name-box">
						<p id="landZoneFileName" className="file-upload-name" >{name}</p>
					</div>
					</Grid>
                </Grid>

                <Button
                    size='large'
                    className='submit-button'
                    variant='contained'
					onClick={uploadFile}
                >
                    Submit
                </Button>
            </FormControl>
        </div>
    );
}